'use client';

import useOutsideClick from '@/src/hooks/useOutsideClick';
import clsx from 'clsx';
import Link from 'next/link';
import { useRef, useState } from 'react';
import { IoIosArrowDropdownCircle } from 'react-icons/io';
import styles from './styles.module.css';

export default function NavBarCta({
  className,
  animate,
  isDarkMode = false,
}: {
  className: string;
  animate: boolean;
  isDarkMode?: boolean;
}) {
  const [isSignInOpen, setIsSignInOpen] = useState(false);
  const signInRef = useRef<HTMLDivElement>(null);

  useOutsideClick(signInRef, () => setIsSignInOpen(false));

  return (
    <div className={className}>
      <div className="relative">
        <Link
          className="flex text-center items-center justify-center py-3 w-[7rem] min-w-[7rem] rounded-full bg-blue-700 text-white text-sm font-medium leading-normal cursor-pointer"
          href="/client/auth/select-signup"
        >
          Sign up
        </Link>
      </div>
      <div className="relative">
        <div
          className={clsx(
            isDarkMode
              ? 'bg-slate-900 text-white'
              : 'bg-neutral-200 text-neutral-70',
            'flex text-center items-center justify-center py-2.5 w-[7rem] min-w-[7rem] rounded-full gap-1 cursor-pointer',
            animate && styles.secondary_cta
          )}
          onClick={() => setIsSignInOpen(!isSignInOpen)}
        >
          Login
          <IoIosArrowDropdownCircle size={22} className="text-blue-700" />
        </div>
        {isSignInOpen && (
          <div
            className={clsx(
              isDarkMode ? 'bg-[#1B213C]' : 'bg-zinc-100 ',
              'shadow rounded-md w-fit h-fit absolute right-0 top-14'
            )}
            ref={signInRef}
          >
            <div className="flex flex-col">
              <Link
                className={clsx(
                  isDarkMode
                    ? 'text-white '
                    : 'text-zinc-800 hover:bg-zinc-200 ',
                  ' text-opacity-75 text-sm font-normal leading-normal cursor-pointer whitespace-nowrap  p-2 transition-colors'
                )}
                href="/client/auth/signin"
              >
                Client
              </Link>
              <Link
                className={clsx(
                  isDarkMode ? 'text-white' : 'text-zinc-800 hover:bg-zinc-200',
                  ' text-opacity-75 text-sm font-normal leading-normal cursor-pointer whitespace-nowrap p-2 transition-colors'
                )}
                href="/candidate/auth/signin"
              >
                Candidate
              </Link>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
